import * as React from "react";
import { HighscoreState } from "../types";
import { useSelector } from "react-redux";
import { Score } from "./Score";
import { useFirebaseConnect } from "react-redux-firebase";
import { AppState } from "../store";

export interface IGlobalScore {
  highscoreState: HighscoreState;
}

export const GlobalScore: React.FC<IGlobalScore> = props => {
  useFirebaseConnect([
    {
      path: "/score",
      queryParams: ["orderByChild=durationSecondsTotal", "limitToFirst=10000"]
    }
  ]);

  const scores: any[] = useSelector(
    (state: AppState) => state.firebase.ordered.score
  );

  return (
    <div>
      {scores && scores.length > 0 && (
        <div className="highscore">
          {scores.map((score: any, index: number) => {
            if (
              index < 100 ||
              props.highscoreState.keyOfLastGame === score.key
            ) {
              return (
                <Score
                  key={score.key}
                  score={score.value}
                  scoreKey={score.key}
                  rank={index + 1}
                  keyOfLastGame={props.highscoreState.keyOfLastGame}
                />
              );
            }
            return null;
          })}
        </div>
      )}
    </div>
  );
};
