import * as React from "react";
import { HighscoreState } from "../types";

import { Score } from "./Score";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { loadLocalPairsScoreDb } from "../actions/highscore";

export interface ILocalScore {
  highscoreState: HighscoreState;
}

export const LocalScore: React.FC<ILocalScore> = props => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadLocalPairsScoreDb());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      {props.highscoreState.localScores &&
        props.highscoreState.localScores.length > 0 && (
          <div className="highscore">
            {props.highscoreState.localScores.map(
              (localScore: any, index: number) => {
                if (
                  index < 100 ||
                  props.highscoreState.idOfLastGame === localScore.id
                ) {
                  return (
                    <Score
                      key={localScore.id}
                      score={localScore}
                      scoreKey={localScore.id}
                      rank={index + 1}
                      keyOfLastGame={props.highscoreState.idOfLastGame}
                    />
                  );
                }
                return null;
              }
            )}
          </div>
        )}
    </div>
  );
};
