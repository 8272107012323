import { createStore, combineReducers, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { firebaseReducer, getFirebase } from "react-redux-firebase";
import thunk from "redux-thunk";
import { pairsReducer } from "../reducers/pairs";
import { highscoreReducer } from "../reducers/highscore";

const rootReducer = combineReducers({
  pairs: pairsReducer,
  highscore: highscoreReducer,
  firebase: firebaseReducer
});

export type AppState = ReturnType<typeof rootReducer>;

export default function configureStore() {
  const middlewares = [thunk.withExtraArgument(getFirebase)];
  const middleWareEnhancer = applyMiddleware(...middlewares);

  const store = createStore(
    rootReducer,
    composeWithDevTools(middleWareEnhancer)
  );

  return store;
}
