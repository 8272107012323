import * as React from "react";
import { useDispatch } from "react-redux";
import { initGameAction } from "../actions/pairs";

function pad(num: number, size: number) {
  let numStr = num.toString();
  while (numStr.length < size) numStr = "0" + numStr;
  return numStr;
}

export interface IScoreboardDuel {
  unfoundPairs: number;
  startTime: number;
  duelId: string;

  hostPlayersTurn: boolean;
  hostPlayersWonPairs: number;
  guestPlayersWonPairs: number;

  hostPlayer: boolean;
}

export const Duelboard: React.FC<IScoreboardDuel> = props => {
  const dispatch = useDispatch();

  let endgameclass = "container";
  if (props.unfoundPairs === 0) {
    endgameclass += " endgame";
  }

  const onInvitePlayer = () => {
    navigator.share({
      title: "TschuttiGames",
      text: "Play the TschuttiGames in duel mode!",
      url: `${window.location.href}/${props.duelId}`
    });
  };

  let buttonHighscoreArea;
  if (props.startTime === 0) {
    buttonHighscoreArea = (
      <div className="buttonHighscoreArea">
        <button className="btn" onClick={onInvitePlayer}>
          Spieler einladen | Invite player
        </button>
      </div>
    );
  } else {
    buttonHighscoreArea = (
      <div className="buttonHighscoreArea">
        <button
          className="btn"
          onClick={() => dispatch(initGameAction(props.duelId))}
        >
          Neues Spiel starten | New game
        </button>
      </div>
    );
  }

  let hostStar = "\u00a0\u00a0";
  let guestStar = "\u00a0\u00a0";
  if (props.hostPlayersTurn) {
    hostStar = "*\u00a0";
    if (props.hostPlayer) {
      hostStar = "**";
    }
  } else {
    guestStar = "\u00a0*";
    if (!props.hostPlayer) {
      guestStar = "**";
    }
  }

  return (
    <section className={endgameclass}>
      <div className="scoreboard">
        {buttonHighscoreArea}
        <div className="timer">
          <span className="durationMinutes">
            {`${hostStar}HEIM ${pad(props.hostPlayersWonPairs, 2)}`}
          </span>
          <span className="durationSeconds">
            :{`${pad(props.guestPlayersWonPairs, 2)} GAST${guestStar}`}
          </span>
        </div>
      </div>
    </section>
  );
};
