import * as React from "react";
import { Score as ScoreType } from "../types";
import {
  getCentSecFromTotalSeconds,
  getMinutesFromTotalSeconds,
  getSecondsFromTotalSeconds
} from "../actions/pairs";

export interface IScore {
  score: ScoreType;
  scoreKey: string;
  rank: number;
  keyOfLastGame: string;
}

export const Score: React.FC<IScore> = props => {
  let scoreClass = "scoreName";
  let scoreClassName = "score";

  if (props.scoreKey === props.keyOfLastGame) {
    scoreClass = "scoreLastGame";
    scoreClassName = "scoreNameLastGame";
  }

  return (
    <div className={scoreClass}>
      <span className="scoreRank">{props.rank}.</span>
      &nbsp;
      <span className="scoreMinutes">
        {getMinutesFromTotalSeconds(props.score.durationSecondsTotal)}
      </span>
      <span className="scoreSeconds">
        :{getSecondsFromTotalSeconds(props.score.durationSecondsTotal)}
      </span>
      <span className="scoreCentSec">
        :{getCentSecFromTotalSeconds(props.score.durationSecondsTotal)}
      </span>
      &nbsp;
      <span className={scoreClassName}>{props.score.name}</span>
    </div>
  );
};
