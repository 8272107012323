import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Helmet } from "react-helmet";
import { AppState } from "../store";
import { PairsState } from "../types";
import { useEffect } from "react";
import { Cardboard } from "./Cardboard";
import { Scoreboard } from "./Scoreboard";
import { initGameAction } from "../actions/pairs";

export const Pairs: React.FC = () => {
  const pairsState: PairsState = useSelector((state: AppState) => state.pairs);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(initGameAction(null));
    const interval = setInterval(() => {
      dispatch({
        type: "TICK"
      });
    }, 50);
    return () => clearInterval(interval);
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <Helmet>
        <title>Play now! | TschuttiGames</title>
      </Helmet>
      <Scoreboard
        unfoundPairs={pairsState.unfoundPairs}
        startTime={pairsState.startTime}
        endTime={pairsState.endTime}
        durationSecondsTotal={pairsState.durationSecondsTotal}
        showFormEnterName={pairsState.showFormEnterName}
        inputEnterName={pairsState.inputEnterName}
      />
      <Cardboard
        cardset={pairsState.cardset}
        photosetTotal={pairsState.photosetTotal}
        duel={null}
        hostPlayer={true}
        hostPlayersTurn={true}
      />
    </div>
  );
};
