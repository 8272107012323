import * as React from "react";
import { useDispatch } from "react-redux";
import {
  getCentSecFromTotalSeconds,
  getMinutesFromTotalSeconds,
  getSecondsFromTotalSeconds,
  initGameAction
} from "../actions/pairs";
import { Link } from "react-router-dom";
import { FormEnterName } from "./FormEnterName";

export interface IScoreboard {
  unfoundPairs: number;
  startTime: number;
  endTime: number;
  durationSecondsTotal: number;
  showFormEnterName: boolean;
  inputEnterName: string;
}

export const Scoreboard: React.FC<IScoreboard> = props => {
  const dispatch = useDispatch();

  let endgameclass = "container";
  if (props.unfoundPairs === 0) {
    endgameclass += " endgame";
  }

  let buttonHighscoreArea;
  if (props.startTime === 0 || props.endTime > 0) {
    buttonHighscoreArea = (
      <div className="buttonHighscoreArea">
        <Link className="btn" to="/highscore">
          Spielstand | Highscore
        </Link>{" "}
        <Link className="btn" to="/about">
          Impressum | About
        </Link>
      </div>
    );
  } else {
    buttonHighscoreArea = (
      <div className="buttonHighscoreArea">
        <button className="btn" onClick={() => dispatch(initGameAction(null))}>
          Spiel abbrechen | Abort game
        </button>
      </div>
    );
  }

  return (
    <section className={endgameclass}>
      <div className="scoreboard">
        {buttonHighscoreArea}
        <div className="timer">
          <span className="durationMinutes">
            {getMinutesFromTotalSeconds(props.durationSecondsTotal)}
          </span>
          <span className="durationSeconds">
            :{getSecondsFromTotalSeconds(props.durationSecondsTotal)}
          </span>
          <span className="durationCentSec">
            :{getCentSecFromTotalSeconds(props.durationSecondsTotal)}
          </span>
        </div>
        {props.showFormEnterName && (
          <FormEnterName
            durationSecondsTotal={props.durationSecondsTotal}
            inputEnterName={props.inputEnterName}
          />
        )}
      </div>
    </section>
  );
};
