import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Helmet } from "react-helmet";
import { AppState } from "../store";
import { PairsState } from "../types";
import { useEffect, useState } from "react";
import { Cardboard } from "./Cardboard";
import { Duelboard } from "./Duelboard";
import { useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { useFirebaseConnect } from "react-redux-firebase";
import { clickCard, initGame, initGameAction } from "../actions/pairs";

export const Duel: React.FC = () => {
  // @ts-ignore
  let { paramDuelId } = useParams();
  const [duelId] = useState(paramDuelId ?? uuidv4());
  const [hostPlayer] = useState(paramDuelId === undefined);
  const pairsState: PairsState = useSelector((state: AppState) => state.pairs);
  const dispatch = useDispatch();

  useFirebaseConnect([
    {
      path: `/${duelId}`,
      queryParams: ["orderByChild=timestamp", "limitToFirst=10000"]
    }
  ]);

  const events: any[] = useSelector(
    (state: AppState) => state.firebase.ordered[duelId]
  );

  useEffect(() => {
    if (events) {
      for (var i = 0; i < events.length; i++) {
        const event = events[i];
        if (event.value.type === "INIT_GAME") {
          dispatch(
            initGame(
              event.value.payload.photosetTotal,
              event.value.payload.cardset
            )
          );
        }
        if (event.value.type === "CLICK_CARD") {
          dispatch(clickCard(event.value.payload));
        }
      }
    }
  }, [events, dispatch]);

  useEffect(() => {
    if (hostPlayer) {
      //console.log(duelId);
      dispatch(initGameAction(duelId));
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <Helmet>
        <title>Duel now! | TschuttiGames</title>
      </Helmet>
      <Duelboard
        unfoundPairs={pairsState.unfoundPairs}
        startTime={pairsState.startTime}
        duelId={duelId}
        hostPlayersTurn={pairsState.hostPlayersTurn}
        hostPlayersWonPairs={pairsState.hostPlayersWonPairs}
        guestPlayersWonPairs={pairsState.guestPlayersWonPairs}
        hostPlayer={hostPlayer}
      />
      <Cardboard
        cardset={pairsState.cardset}
        photosetTotal={pairsState.photosetTotal}
        duel={duelId}
        hostPlayer={hostPlayer}
        hostPlayersTurn={pairsState.hostPlayersTurn}
      />
    </div>
  );
};
