import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../store";
import { HighscoreState } from "../types";
import { Link } from "react-router-dom";
import { changeType } from "../actions/highscore";
import { GlobalScore } from "./GlobalScore";
import { TodayScore } from "./TodayScore";
import { LocalScore } from "./LocalScore";
import { Helmet } from "react-helmet";

export const Highscore: React.FC = () => {
  const dispatch = useDispatch();
  const highscoreState: HighscoreState = useSelector(
    (state: AppState) => state.highscore
  );

  return (
    <div>
      <section className={"container endgame"}>
        <div className="scoreboard">
          <div className="timer">
            <span className="durationMinutes">
              {highscoreState.type === "global" && (
                <>
                  <Helmet>
                    <title>Global Highscore | TschuttiGames</title>
                  </Helmet>
                  <span role="img" aria-label="global">
                    🌍
                  </span>
                </>
              )}
              {highscoreState.type === "today" && (
                <>
                  <Helmet>
                    <title>Today's Highscore | TschuttiGames</title>
                  </Helmet>
                  <span role="img" aria-label="today">
                    📆
                  </span>
                </>
              )}
              {highscoreState.type === "local" && (
                <>
                  <Helmet>
                    <title>Local Highscore | TschuttiGames</title>
                  </Helmet>
                  <span role="img" aria-label="local">
                    🏡
                  </span>
                </>
              )}{" "}
              Highscore
            </span>
          </div>

          <div className="buttonRestartArea">
            <Link to={"/"} className={"btn"}>
              Neues Spiel | New game
            </Link>{" "}
            <button
              className="btn"
              onClick={() => dispatch(changeType("global"))}
            >
              Globale Rangliste | Global highscores
            </button>{" "}
            <button
              className="btn"
              onClick={() => dispatch(changeType("today"))}
            >
              Rangliste des Tages | Highscores of today
            </button>{" "}
            <button
              className="btn"
              onClick={() => dispatch(changeType("local"))}
            >
              Rangliste dieses Browsers | Browser highscores
            </button>
          </div>
          {highscoreState.type === "global" && (
            <GlobalScore highscoreState={highscoreState} />
          )}
          {highscoreState.type === "today" && (
            <TodayScore highscoreState={highscoreState} />
          )}
          {highscoreState.type === "local" && (
            <LocalScore highscoreState={highscoreState} />
          )}
        </div>
      </section>
    </div>
  );
};
