import { PairsActionTypes } from "../types/pairs";
import { PairsState } from "../types";
import produce from "immer";

const cardsLength = Number(process.env.REACT_APP_CARDS_LENGTH); // default is 18

const initialState: PairsState = {
  cardsLength: cardsLength,
  unfoundPairs: 0,
  startTime: 0,
  endTime: 0,
  durationSecondsTotal: 0,
  showFormEnterName: false,
  inputEnterName: "",
  hostPlayersTurn: true,
  hostPlayersWonPairs: 0,
  guestPlayersWonPairs: 0,
  cardset: [],
  photosetTotal: []
};

export function pairsReducer(
  state: PairsState = initialState,
  action: PairsActionTypes
): PairsState {
  return produce(state, (draft: PairsState) => {
    switch (action.type) {
      case "INIT_GAME":
        console.log("INIT_GAME", action.payload);

        draft.cardsLength = cardsLength;
        draft.unfoundPairs = cardsLength / 2;
        draft.cardset = [];
        draft.startTime = 0;
        draft.endTime = 0;
        draft.durationSecondsTotal = 0;
        draft.showFormEnterName = false;

        draft.hostPlayersTurn = true;
        draft.hostPlayersWonPairs = 0;
        draft.guestPlayersWonPairs = 0;

        draft.photosetTotal = action.payload.photosetTotal;
        draft.cardset = action.payload.cardset;

        break;

      case "TICK":
        let sec = 0;
        if (state.startTime > 0) {
          if (state.endTime > 0) {
            sec = (state.endTime - state.startTime) / 1000;
          } else {
            sec = (Date.now() - state.startTime) / 1000;
          }
        }
        draft.durationSecondsTotal = sec;

        break;

      case "CLICK_CARD":
        //console.log("CLICK_CARD", action.payload);
        const cardIndex = action.payload.cardIndex;

        // set start time if 0
        if (state.startTime === 0) {
          draft.startTime = Date.now();
        }

        // collect face up cards in state
        let faceUpCardsState = [];
        for (let i = 0; i < cardsLength; i++) {
          if (state.cardset[i].side === "face") {
            faceUpCardsState.push(i);
          }
        }

        if (faceUpCardsState.length < 3) {
          // if the card is not won jet
          if (state.cardset[cardIndex].status !== "won") {
            // face up the clicked card
            draft.cardset[cardIndex].side = "face";
          }
        }

        // check if pair in state is lost or won
        if (faceUpCardsState.length === 2) {
          if (
            draft.cardset[faceUpCardsState[0]].photosetTotalIndex ===
            draft.cardset[faceUpCardsState[1]].photosetTotalIndex
          ) {
            // pair is won
            // console.debug("pair is won");
            draft.cardset[faceUpCardsState[0]].status = "won";
            draft.cardset[faceUpCardsState[0]].side = "deck";
            draft.cardset[faceUpCardsState[1]].status = "won";
            draft.cardset[faceUpCardsState[1]].side = "deck";
            draft.unfoundPairs -= 1;
            /*
            if (draft.unfoundPairs === 0) {
              // END OF GAME
              // set end time
              draft.endTime = Date.now();
              draft.showFormEnterName = true;
            }
            */
          } else {
            // pair is lost
            draft.cardset[faceUpCardsState[0]].side = "back";
            draft.cardset[faceUpCardsState[1]].side = "back";
            // console.debug("pair is lost");
          }
        }

        // collect face up cards in draft
        let faceUpCardsDraft = [];
        for (let i = 0; i < cardsLength; i++) {
          if (draft.cardset[i].side === "face") {
            faceUpCardsDraft.push(i);
          }
        }

        // check if pair in draft is lost or won
        if (faceUpCardsDraft.length === 2) {
          if (
            state.cardset[faceUpCardsDraft[0]].photosetTotalIndex ===
            state.cardset[faceUpCardsDraft[1]].photosetTotalIndex
          ) {
            if (state.hostPlayersTurn) {
              draft.hostPlayersWonPairs = state.hostPlayersWonPairs + 1;
            } else {
              draft.guestPlayersWonPairs = state.guestPlayersWonPairs + 1;
            }

            // pair and game is won
            // console.debug("pair and game is won");
            if (state.unfoundPairs - 1 === 0) {
              // END OF GAME
              // set end time
              draft.endTime = Date.now();
              draft.showFormEnterName = true;

              draft.cardset[faceUpCardsDraft[0]].status = "won";
              draft.cardset[faceUpCardsDraft[0]].side = "deck";
              draft.cardset[faceUpCardsDraft[1]].status = "won";
              draft.cardset[faceUpCardsDraft[1]].side = "deck";
            }
          } else {
            draft.hostPlayersTurn = !state.hostPlayersTurn;
          }
        }

        break;

      case "CHANGE_ENTER_NAME":
        draft.inputEnterName = action.payload;
        break;

      case "SHOW_FORM_ENTER_NAME":
        draft.showFormEnterName = action.payload;
        break;
    }
  });
}
