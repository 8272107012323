import * as React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

export const About: React.FC = () => {
  return (
    <div>
      <Helmet>
        <title>About | TschuttiGames</title>
      </Helmet>
      <section className={"container endgame"}>
        <div className="scoreboard">
          <div className="timer">
            <span className="durationMinutes">
              <span role="img" aria-label="tschutti">
                ⚽
              </span>{" "}
              About
            </span>
          </div>

          <div className="buttonRestartArea">
            <Link to={"/"} className={"btn"}>
              Neues Spiel | New game
            </Link>{" "}
          </div>
          <div className="highscore">
            <div className="score">
              Erstellt für{" "}
              <a
                href="https://www.jobtransfair.at/"
                target="_blank"
                rel="noopener noreferrer"
              >
                jobtransfair.at
              </a>
            </div>
            <div className="score">
              Von{" "}
              <a
                href="https://www.linkedin.com/in/marco-egli-intelliact/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Marco Egli
              </a>
            </div>
            <div className="score">
              Mit Bildern von{" "}
              <a
                href="https://www.tschuttiheft.li/"
                target="_blank"
                rel="noopener noreferrer"
              >
                tschuttiheftli
              </a>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
