import produce from "immer";
import { HighscoreActionTypes } from "../types/highscore";
import { HighscoreState } from "../types";

const initialState: HighscoreState = {
  showHighscore: true,
  keyOfLastGame: "",
  idOfLastGame: "",
  localScores: [],
  type: "global"
};

export function highscoreReducer(
  state: HighscoreState = initialState,
  action: HighscoreActionTypes
): HighscoreState {
  return produce(state, (draft: HighscoreState) => {
    switch (action.type) {
      case "SUBMIT_PAIRS_SCORE":
        draft.showHighscore = true;
        draft.keyOfLastGame = action.payload.keyOfLastGame;
        break;

      case "SET_LOCAL_PAIRS_SCORE":
        draft.showHighscore = true;
        if (action.payload.idOfLastGame !== "") {
          draft.idOfLastGame = action.payload.idOfLastGame;
        }
        draft.localScores = action.payload.localScores;
        break;

      case "CHANGE_TYPE":
        draft.type = action.payload;
        break;
    }
  });
}
