import { PairsActionTypes } from "../types/pairs";
import { Card } from "../types";
import { photosetTotalOnline } from "../store/photosetTotalOnline";
import { photosetTotalOffline } from "../store/photosetTotalOffline";
import { getFirebase } from "react-redux-firebase";
// @ts-ignore
import gtag from "ga-gtag";

export function initGame(
  photosetTotal: string[],
  cardset: Card[]
): PairsActionTypes {
  return {
    type: "INIT_GAME",
    payload: {
      photosetTotal,
      cardset
    }
  };
}

export function tick(): PairsActionTypes {
  return { type: "TICK" };
}

export function clickCard(card: Card): PairsActionTypes {
  return { type: "CLICK_CARD", payload: card };
}

export function changeEnterName(name: string): PairsActionTypes {
  return { type: "CHANGE_ENTER_NAME", payload: name };
}

export function showFormEnterName(showForm: boolean): PairsActionTypes {
  return { type: "SHOW_FORM_ENTER_NAME", payload: showForm };
}

//

export function initGameAction(duelId: string | null) {
  const cardsLength = Number(process.env.REACT_APP_CARDS_LENGTH); // default is 18
  var photosetTotal: string[] = [];
  var cardset: Card[] = [];
  let photosetGame = [];
  let photosetTotalIndex: number = 0;

  if (navigator.onLine) {
    // online
    photosetTotal = photosetTotalOnline;
  } else {
    // offline
    photosetTotal = photosetTotalOffline;
  }

  while (photosetGame.length < cardsLength / 2) {
    photosetTotalIndex = Math.floor(Math.random() * photosetTotal.length);
    if (photosetGame.indexOf(photosetTotalIndex) < 0) {
      photosetGame.push(photosetTotalIndex);

      let cardAssigns = 0;
      let cardIndex = 0;
      while (cardAssigns < 2) {
        cardIndex = Math.floor(Math.random() * cardsLength);

        if (cardset[cardIndex] === undefined) {
          cardset[cardIndex] = {
            cardIndex: cardIndex,
            status: "ingame",
            photosetTotalIndex: photosetTotalIndex,
            side: "back"
          };
          cardAssigns++;
        }
      }
    }
  }

  let level_name = "Pairs";
  if (duelId !== null) {
    level_name = "Duel";
    const event = {
      type: "INIT_GAME",
      timestamp: Date.now(),
      payload: {
        photosetTotal,
        cardset
      }
    };

    getFirebase().remove(duelId);

    getFirebase()
      .ref(duelId)
      .push(event)
      .then(() => {
        console.log("event added to database");
      });
  }

  gtag("event", "level_start", {
    level_name: level_name
  });

  return (dispatch: any) => {
    dispatch(initGame(photosetTotal, cardset));
  };
}

export function clickCardDb(card: Card) {
  return (dispatch: any, getState: any, getFirebase: any) => {
    dispatch(clickCard(card));
    return getFirebase()
      .ref("card_click")
      .push(card)
      .then(() => {
        console.log("card_click added to database");
      });
  };
}

//

function pad(num: number, size: number) {
  var s = "000000000" + num;
  return s.substr(s.length - size);
}

export function getMinutesFromTotalSeconds(s: number) {
  let min = Math.floor(s / 60);
  if (min < 100) {
    return pad(min, 2);
  } else {
    return min + "";
  }
}

export function getSecondsFromTotalSeconds(s: number) {
  return pad(Math.floor(s % 60), 2);
}
export function getCentSecFromTotalSeconds(s: number) {
  return pad(Math.round((s * 100) % 1000), 2);
}
