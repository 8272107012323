import * as React from "react";
import { Card as CardType } from "../types";
import { cardBgImg } from "../store/cardBgImgSrc";
import { useDispatch } from "react-redux";
import { clickCardDb } from "../actions/pairs";
import { useState } from "react";
import { getFirebase } from "react-redux-firebase";

export interface ICard extends CardType {
  src: string;
  duel: null | string;
  hostPlayer: boolean;
  hostPlayersTurn: boolean;
}

export const Card: React.FC<ICard> = props => {
  const dispatch = useDispatch();
  const [cardFrontImgSrc, setCardFrontImgSrc] = useState(cardBgImg);
  //const [cardBackImgSrc, setCardBackImgSrc] = useState(cardBgImg);
  /*
  useEffect(() => {
    setCardBackImgSrc(cardBgImgs[Math.floor(Math.random() * 2)]);
  }, []);
   */

  let classNameCard = "won";
  if (props.side === "face") {
    classNameCard = "faceup";
    if (cardFrontImgSrc !== props.src) {
      setCardFrontImgSrc(props.src);
    }
  }
  if (props.side === "back") {
    classNameCard = "facedown";
  }

  const onCardClick = function() {
    if (props.duel === null) {
      dispatch(clickCardDb(props));
    } else {
      if (props.hostPlayer === props.hostPlayersTurn) {
        const event = {
          type: "CLICK_CARD",
          timestamp: Date.now(),
          payload: props
        };

        getFirebase()
          .ref(props.duel)
          .push(event)
          .then(() => {
            console.log("event added to database");
          });
      } else {
        console.log("click ignored");
      }
    }
  };

  return (
    <div className={"card " + classNameCard} onMouseDown={onCardClick}>
      <img
        className="inner card__face card__face--front"
        src={`${cardFrontImgSrc}`}
        alt={props.side}
      />

      <img
        className="inner card__face card__face--back"
        src={cardBgImg}
        alt={props.side}
      />
    </div>
  );
};
