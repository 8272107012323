import React from "react";
import { Router, Switch, Route } from "react-router";
import { Link } from "react-router-dom";

import { Pairs } from "./components/Pairs";
import { About } from "./components/About";
import { Highscore } from "./components/Highscore";

import { createBrowserHistory } from "history";
import { Duel } from "./components/Duel";

export const history = createBrowserHistory();

function App() {
  return (
    <div className="App">
      <Router history={history}>
        <header className="header">
          <section className="container">
            <div className="headercontent">
              <Link to="/">Pairs Game</Link>&nbsp;|&nbsp;
              <Link to="/duel">Duel (Beta)</Link>&nbsp;|&nbsp;
              <Link to="/highscore">Highscore</Link>&nbsp;|&nbsp;
              <Link to="/about">About</Link>
            </div>
          </section>
        </header>

        <Switch>
          <Route exact path="/">
            <Pairs />
          </Route>
          <Route path="/duel/:paramDuelId?">
            <Duel />
          </Route>
          <Route path="/highscore">
            <Highscore />
          </Route>
          <Route path="/about">
            <About />
          </Route>
        </Switch>
      </Router>

      <footer className="footer fixed-bottom">
        <section className="container">
          <div className="footercontent">
            Erstellt für{" "}
            <a
              href="https://www.jobtransfair.at/"
              target="_blank"
              rel="noopener noreferrer"
            >
              jobtransfair.at
            </a>
            &nbsp;von{" "}
            <a
              href="https://www.linkedin.com/in/marco-egli-intelliact/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Marco Egli
            </a>
            &nbsp;mit Bildern von{" "}
            <a
              href="https://www.tschuttiheft.li/"
              target="_blank"
              rel="noopener noreferrer"
            >
              tschuttiheft.li
            </a>
            &nbsp;|&nbsp;Version 2022.1.0
          </div>
        </section>
      </footer>
    </div>
  );
}

export default App;
