import * as React from "react";
import { Score } from "../types";
import { useDispatch, useSelector } from "react-redux";
import { changeEnterName } from "../actions/pairs";
import { getTodayNumber, submitPairsScoreDb } from "../actions/highscore";
import { useFirebaseConnect } from "react-redux-firebase";
import { AppState } from "../store";

export interface IFormEnterName {
  durationSecondsTotal: number;
  inputEnterName: string;
}

export const FormEnterName: React.FC<IFormEnterName> = props => {
  const dispatch = useDispatch();
  useFirebaseConnect([
    {
      path: "/score",
      queryParams: ["orderByChild=durationSecondsTotal", "limitToFirst=10000"]
    }
  ]);

  const scores: any[] = useSelector(
    (state: AppState) => state.firebase.ordered.score
  );

  let rank = 1;
  if (scores && scores.length > 0) {
    rank =
      1 +
      scores.findIndex(
        el => el.value.durationSecondsTotal > props.durationSecondsTotal
      );
    // letzter
    if (rank === 0) {
      rank = scores.length + 1;
    }
    if (rank > 9999) {
      rank = 9999;
    }
  }

  return (
    <form
      className="formEnterName"
      onSubmit={e => {
        e.stopPropagation();
        e.preventDefault();
        const score: Score = {
          durationSecondsTotal: props.durationSecondsTotal,
          name: props.inputEnterName,
          playDate: getTodayNumber()
        };
        dispatch(submitPairsScoreDb(score));
      }}
    >
      <span className="scoreRank">{rank}.</span>
      &nbsp;
      <input
        type="text"
        onChange={e => dispatch(changeEnterName(e.target.value))}
        value={props.inputEnterName}
        placeholder="Namen eingeben | Enter name"
        maxLength={60}
        required={true}
      />
      <br />
      <button type="submit" className="btn">
        Speichern | Save
      </button>
    </form>
  );
};
