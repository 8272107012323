import * as React from "react";
import { Card as ICard } from "../types";
import { Card } from "./Card";

export interface ICardboard {
  cardset: ICard[];
  photosetTotal: string[];
  duel: null | string;
  hostPlayer: boolean;
  hostPlayersTurn: boolean;
}

export const Cardboard: React.FC<ICardboard> = props => {
  return (
    <section className="container cardboard">
      {props.cardset.map((card: ICard, index: number) => {
        return (
          <Card
            key={card.cardIndex}
            cardIndex={card.cardIndex}
            status={card.status}
            photosetTotalIndex={card.photosetTotalIndex}
            side={card.side}
            src={props.photosetTotal[card.photosetTotalIndex]}
            duel={props.duel}
            hostPlayer={props.hostPlayer}
            hostPlayersTurn={props.hostPlayersTurn}
          />
        );
      })}
    </section>
  );
};
