import { HighscoreActionTypes } from "../types/highscore";
import { Score } from "../types";
import { showFormEnterName } from "./pairs";
import { history } from "../App";
import dexieDb from "../store/dexieDb";

export function submitPairsScore(
  score: Score,
  keyOfLastGame: string
): HighscoreActionTypes {
  return { type: "SUBMIT_PAIRS_SCORE", payload: { score, keyOfLastGame } };
}

export function setLocalPairsScore(
  localScores: Score[],
  idOfLastGame: string
): HighscoreActionTypes {
  return {
    type: "SET_LOCAL_PAIRS_SCORE",
    payload: { localScores, idOfLastGame }
  };
}

export function changeType(type: string): HighscoreActionTypes {
  return { type: "CHANGE_TYPE", payload: type };
}

//

export function submitPairsScoreDb(score: Score) {
  return (dispatch: any, getState: any, getFirebase: any) => {
    dispatch(showFormEnterName(false));
    history.push("/highscore");
    const idOfLastGame =
      "_" +
      Math.random()
        .toString(36)
        .substr(2, 9);
    dexieDb.scores.add({
      id: idOfLastGame,
      durationSecondsTotal: score.durationSecondsTotal,
      name: score.name,
      playDate: score.playDate
    });
    dexieDb.scores
      .orderBy("durationSecondsTotal")
      .limit(1000)
      .toArray(function(scores) {
        dispatch(setLocalPairsScore(scores, idOfLastGame));
      });

    return getFirebase()
      .ref("score")
      .push(score)
      .then((e: any) => {
        var keyOfLastGame = e.key;
        dispatch(submitPairsScore(score, keyOfLastGame));
      });
  };
}

export function loadLocalPairsScoreDb() {
  return (dispatch: any) => {
    dexieDb.scores
      .orderBy("durationSecondsTotal")
      .limit(1000)
      .toArray(function(scores) {
        dispatch(setLocalPairsScore(scores, ""));
      });
  };
}

//

function pad(num: number, size: number): string {
  var s = num + "";
  while (s.length < size) s = "0" + s;
  return s;
}

export function getTodayNumber(): number {
  const today = new Date();
  const todayString =
    "" +
    today.getFullYear() +
    pad(today.getMonth(), 2) +
    pad(today.getDate(), 2);
  return parseInt(todayString, 10);
}
