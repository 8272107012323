import Dexie from "dexie";
import { Score } from "../types";

interface IScore extends Score {
  id: string;
}

//
// Declare Database
//
class TschuttiGameDb extends Dexie {
  // @ts-ignore
  scores: Dexie.Table<IScore, number>;

  constructor() {
    super("TschuttiGameDb");
    this.version(1).stores({
      scores: "++id, durationSecondsTotal, name, playDate"
    });
    this.scores = this.table("scores");
  }
}

const dexieDb = new TschuttiGameDb();

export default dexieDb;
